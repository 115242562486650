import { Component, OnInit } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { environment } from '../../../../environments/environment';
import { CityService } from '../header/services/city.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  redirectUrl: string = environment.portalInmobiliarioLuna;
  activeCity: number | null = null;
  
  constructor(private translationService: TranslationService,
    private cityService: CityService

  ) {
    this.cityService.selectedCity$.subscribe(cityId => {
      this.activeCity = cityId;
    });
  }

  ngOnInit(): void {
  }

  goToSeizaPage() {
    window.open("http://seiza-ti.cl", '_blank');
  }

  onCitySelected(cityId: number) {
    this.activeCity = cityId;
  }



}