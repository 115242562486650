<div class="flex flex-wrap space-x-0 lg:space-x-3 gap-[8px] lg:gap-0">
  @for (city of cities; track city.id) {
    <div class="flex items-center mt-4 lg:mt-0"> <img *ngIf="city.paisImage" [src]="city.paisImage" alt="{{ city.name }} Flag" class="h-4 w-4 mr-2">  <ng-container *ngIf="city.id === 4; else otherCities">
        <a href="{{urlMiami}}" target="_blank" rel="noopener noreferrer"
           class="{{text}} text-[#97B1B9] cursor-not-allowed pointer-events-none">
          {{ city.name }}
        </a>
      </ng-container>
      <ng-template #otherCities>
        <a (click)="setActiveCity(city.id, city.name)"
           [ngClass]="{
             'font-bold underline': activeCity === city.id,
             'font-light': activeCity !== city.id,
             'text-[#97B1B9] cursor-not-allowed pointer-events-none': city.id !== 3
           }"
           class="{{text}} hover:underline cursor-pointer">
          {{ city.name }}
        </a>
      </ng-template>
    </div>
    <span class="mt-4 lg:mt-0" *ngIf="city.id !== cities.length">|</span>
  }
</div>
