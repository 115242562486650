import { Component, EventEmitter, Input, Output  } from '@angular/core';
import { CityService } from '../header/services/city.service';
import { cities, City } from '../../interfaces/cities.interface';
import { CommonModule } from '@angular/common';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-city-menu',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './city-menu.component.html',
  styleUrl: './city-menu.component.css'
})
export class CityMenuComponent {
  
  @Input() space: string = ''; // Valor por defecto: cadena vacía
  @Input() text: string = '';  // Valor por defecto: cadena vacía
  @Output() citySelected = new EventEmitter<number>();

  cities: City[] = cities; // Lista de ciudades
  activeCity: number = cities[2].id; // ID de Guatemala // Ciudad activa por defecto.
  urlMiami:string = environment.portalInmobiliarioMiami;


  constructor(
    private cityService: CityService,
  ){
    
    this.cityService.selectedCity$.subscribe(cityId => {
      this.activeCity = cityId;
   });

  }


  setActiveCity(id: number, name: string) {
    this.citySelected.emit(id); // Emit the ID
    this.activeCity = id;
    this.cityService.setCity(id); // Actualiza la ciudad en el servicio
  }

}
