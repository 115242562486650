import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { cities, City } from '../../../interfaces/cities.interface';

@Injectable({
  providedIn: 'root'
})
export class CityService {

  cities: City[] = cities; // Lista de ciudades

  private _selectedCity = new BehaviorSubject<number>(cities[2].id); // Valor por defecto
  selectedCity$ = this._selectedCity.asObservable(); // Observable que HomeComponent escuchará

  setCity(city: number) {
    this._selectedCity.next(city); // Actualiza la ciudad
  }
}


