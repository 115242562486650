import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription, take } from 'rxjs';

import { FirebaseService } from '../../services/firebase.service';
import { AuthService } from '../../services/auth.service';
import { BreakPointService } from '../../services/break-point.service';
import { LnDialogComponent } from '../ln-dialog/ln-dialog.component';
import { TranslationService } from '../../services/translation.service';
import { DialogService } from '../../services/dialog.service';
import { StoreFiltersService } from '../../services/storeFilters.service';
import { CityService } from './services/city.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() show: boolean = true;
  @Input() authLayout: boolean = false;
  public showMenu: boolean = false;
  icon = 'menu';
  isLogedin = false;
  subscriptions: Subscription[] = [];
  showSubmenu = false;
  showAlert = false;
  currentLanguage = "es";
  esp: boolean = true;
  hoverText: string | undefined
  tooltipOpen = false;
  primeraVezTooltip = false;
  hasDocumentImage = false;
  activeCity: number | null = null;
  

  constructor(
    private _dialog: MatDialog,
    private router: Router,
    private firebaseService: FirebaseService,
    private auth: AuthService,
    public breakPointService: BreakPointService,
    private _translationService: TranslationService,
    private _dialogService: DialogService,
    private _storeFilterService: StoreFiltersService,
    private cityService: CityService

  ) {

    this.cityService.selectedCity$.subscribe(cityId => {
      this.activeCity = cityId;
    });

    this._translationService.onLangChange.subscribe(() => {//este suscribe se ejecuta cada vez que se cambia el idioma
      this.currentLanguage = this._translationService.getCurrentLang;
      if (this.currentLanguage === 'en')
        this.esp = false;
      else {
        this.esp = true;
      }
      this.toggleMessage(); // Actualizar el texto del tooltip cuando cambia el idioma
    });
  }

  ngOnInit(): void {
    this.auth.documentImage()
    localStorage.getItem('hasImage') === 'true' ? this.hasDocumentImage = true : this.hasDocumentImage = false;

    this.currentLanguage = this._translationService.getCurrentLang;
    if (this.currentLanguage === 'en')
      this.esp = false;
    else {
      this.esp = true;
    }
    const subscription = this.firebaseService
      .isAuthenticatedUser()
      .subscribe((user) => {
        this.isLogedin = user;
        this.auth.isLogedin = user;
      });
    this.subscriptions.push(subscription);
  }

  onCitySelected(cityId: number) {
    this.activeCity = cityId;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public showMenuMobile() {
    this.showMenu = !this.showMenu;
    this.icon = this.showMenu ? 'close' : 'menu';
  }

  toggleSubmenu() {
    this.showSubmenu = !this.showSubmenu;
  }

  public goToHome() {
    this.router.navigateByUrl('');
  }

  public signOut() {
    this.firebaseService.doLogout();
    localStorage.removeItem('hasImage');
    this.goToHome();
  }

  publishProperty() {
    this.router.navigateByUrl('services-form');
  }


  openDialogCloseSession() {
    this._dialog
      .open(LnDialogComponent, {
        width: '380px',
        data: {
          title: 'header.textConfirmCloseSession',
          buttonOk: 'header.textCloseSession',
          buttonCancel: 'header.textNoBack',
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        if (res) this.signOut();
      });
  }

  openDialogCreateAccount() {
    this._dialog
      .open(LnDialogComponent, {
        width: '571px',
        data: {
          title: 'dialogLoginRegistSession.textSignupOrLogin',
          subTitle: 'dialogLoginRegistSession.textCreate',
          buttonOk: 'header.linkLogin',
          buttonCancel: 'dialogLoginRegistSession.textRegister',
          account: true,
        },
      })
  }

  goToMyReservations(){
    this.showMenuMobile()
    if (this.auth.isLogedin) {
      this.router.navigateByUrl('my-reservations');

    } else {
      this._dialogService.openLoginRegistSessionDialog('', this.router.url)
    }
  }


  goToFavorites() {
    this.showMenuMobile()
    if (this.auth.isLogedin) {
      this.router.navigateByUrl('my-favorites');

    } else {
      this._dialogService.openLoginRegistSessionDialog('', this.router.url)
    }
  }

  private timer: any;

  onHover() {
    clearTimeout(this.timer); // Cancela cualquier temporizador existente
    if (!this.showSubmenu) { // Solo abre el menú si no está ya abierto
      this.toggleSubmenu();
    }
  }

  onHoverOut() {
    this.timer = setTimeout(() => {
      if (this.showSubmenu) { // Verifica si el menú está abierto antes de intentar cerrarlo
        this.toggleSubmenu(); // Cierra el menú
      }
    }, 500);
  }


  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event) {
    this.icon = 'menu'
    this.showMenu = false;
    this.hoverText = undefined
  }

  closeSubmenu() {
    if (this.showSubmenu) {
      this.toggleSubmenu();
    }
  }

  toggleMessage() {

    if (this.currentLanguage === 'es') {
      this.hoverText = 'Te faltan datos por completar en la sección “Mi cuenta”.';
      this.primeraVezTooltip = true;
    } else if (this.currentLanguage === 'en') {
      this.hoverText = 'You are missing data to complete in the “My account” section.';
      this.primeraVezTooltip = true;
    } else {
      this.hoverText = undefined;
    }


  }

  onButtonClick() {

    if (this.primeraVezTooltip) {
      this.closeTooltip();

    } else {
      this.toggleMessage();
    }

  }

  closeTooltip() {
    this.primeraVezTooltip = false;
    this.hoverText = undefined;
  }

  goToHomeClearFilters(event: Event) {
    event.preventDefault();
    window.location.href = '/';
    this._storeFilterService.clearAllFilters();
  }


}
